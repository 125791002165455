import { authMiddleware, checkRoleMiddleware } from '@/middleware';
import { isAdmin, isFactory } from '@/utils/role';

export const ROOT = 'dashboard';

export const DOCUMENTS = 'documents';

export const FACTORIES = 'factories';
export const WAREHOUSE = 'warehouse';

const ADD = 'add';
const EDIT = 'edit';
const VIEW = 'view';

export const routes = [
    {
        name: FACTORIES,
        can: () => isAdmin(),
        notSidebar: () => true,
    },
    {
        name: DOCUMENTS,
        can: () => isFactory(),
        notSidebar: () => !isFactory(),
    },
    {
        name: WAREHOUSE,
        can: () => isFactory(),
        notSidebar: () => !isFactory(),
    },
];

export const createRoutes = () => {
    const result = routes
        .map((route) => {
            return [
                {
                    path: `/${ROOT}/${route.name}`,
                    name: route.name,
                    meta: {
                        can: () => route.can(),
                    },
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    component: () => import(`@/views/admin/${route.name}`),
                },
                {
                    path: `/${ROOT}/${route.name}/${ADD}`,
                    name: route.name + '-' + ADD,
                    meta: {
                        can: () => route.can(),
                    },
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    component: () => import(`@/views/admin/${route.name}/${ADD}.vue`),
                },
                {
                    path: `/${ROOT}/${route.name}/:id/${EDIT}`,
                    name: route.name + '-' + EDIT,
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    meta: {
                        can: () => route.can(),
                    },
                    component: () => import(`@/views/admin/${route.name}/${ADD}.vue`),
                },
                {
                    path: `/${ROOT}/${route.name}/${route.name == FACTORIES ? ':factoryId' : ':id'}/${VIEW}`,
                    name: route.name + '-' + VIEW,
                    beforeEnter: [authMiddleware, checkRoleMiddleware],
                    meta: {
                        can: () => route.can(),
                    },
                    component: () => import(`@/views/admin/${route.name}/${VIEW}.vue`),
                },
            ];
        })
        .flat();

    const staticRoutes = [
        {
            path: '/dashboard/factories/:factoryId/document/:id/view',
            name: 'admin-document-view',
            beforeEnter: [authMiddleware, checkRoleMiddleware],
            meta: {
                can: () => isAdmin(),
            },
            component: () => import(`@/views/admin/factories/view-document.vue`),
        },

        {
            path: '/admin/dashboard/factories/:factoryId/warehouse',
            name: 'admin-document-warehouse',
            beforeEnter: [authMiddleware, checkRoleMiddleware],
            meta: {
                can: () => isAdmin(),
            },
            component: () => import(`@/views/admin/factories/warehouse.vue`),
        },
    ];
    return [...result, ...staticRoutes];
};
