import { ref } from 'vue';
import { useRoute } from 'vue-router';

export const usePaginatedData = (p = 'page', size = 'per_page') => {
    const route = useRoute();
    const pagination = ref({
        page: route.query.page && !isNaN(parseFloat(route.query[p])) ? parseFloat(route.query[p]) : 1,
        per_page: route.query.per_page && !isNaN(parseFloat(route.query[size])) ? parseFloat(route.query[size]) : 20,
    });

    const total = ref(0);
    const list = ref([]);
    const loading = ref(false);
    const searchValue = ref('');

    return {
        pagination,
        total,
        list,
        searchValue,
        loading,
    };
};
