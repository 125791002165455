<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo me-5">
                        <a href="http://xarid.uks.uz/" target="_blank">
                            <img :src="require('@/assets/images/logopng.png')" class="navbar-logo" alt="logo" />
                            <b> "Ўзкимёсаноат" АЖ тармоқ корхоналари харид тизими</b>
                        </a>
                    </li>
                </ul>
                <div class="d-none horizontal-menu">
                    <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>
                <div style="margin-left: auto" class="d-flex" v-if="isAdmin()">
                    <BaseButton class="btn-primary px-2 py-1" @ok="$router.push('/dashboard/factories')">{{ $t('admin.sidebar.factories') }}</BaseButton>
                    <h6 class="m-0">
                        <strong>{{ selectedFactory?.name }}</strong>
                    </h6>
                </div>
                <div class="navbar-item flex-row ms-md-auto">
                    <div class="dropdown nav-item user-profile-dropdown btn-group">
                        <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false" class="btn dropdown-toggle btn-icon-only user nav-link">
                            {{ getUser()?.name }}
                            <img class="mx-1" :src="require('@/assets/images/logopng.png')" alt="avatar" />
                        </a>
                        <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
                            <li role="presentation">
                                <router-link to="#" @click="logout" class="dropdown-item">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-log-out"
                                    >
                                        <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                        <polyline points="16 17 21 12 16 7"></polyline>
                                        <line x1="21" y1="12" x2="9" y2="12"></line>
                                    </svg>

                                    {{ $t('btns.logout') }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom" @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue/Teleport for Breadcrumb -->
                <div id="breadcrumb" class="vue-portal-target"></div>
            </header>
        </div>
        <!--  END NAVBAR  -->
    </div>
</template>

<script setup>
    import { getUser, isAdmin } from '@/utils/role';
    import { onMounted, ref, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useStore } from 'vuex';
    import BaseLocaleDropdown from '@/components/custom/BaseLocaLeDropdown.vue';
    import $cookies from 'vue-cookies';
    import { useRouter } from 'vue-router';
    import BaseButton from '@/components/custom/BaseButton.vue';
    import { useSelectedFactory } from '@/composables/use-factories';
    const store = useStore();
    const router = useRouter();
    const { selectedFactory } = useSelectedFactory();
    const selectedLang = ref(null);
    const countryList = ref(store.state.countryList);
    const i18n = useI18n();

    onMounted(() => {
        selectedLang.value = window.$appSetting.toggleLanguage();
        toggleMode();
    });

    const toggleMode = (mode) => {
        window.$appSetting.toggleMode(mode);
    };

    function logout() {
        $cookies.remove('user_token');
        $cookies.remove('user');
        store.commit('setFactories', []);
        router.push('/auth/login');
    }
    const changeLanguage = (item) => {
        selectedLang.value = item;
        window.$appSetting.toggleLanguage(item);

        i18n.locale.value = item.code;
    };

    const searchValue = computed({
        // getter
        get() {
            return store.state.globalSearch;
        },
        // setter
        set(newValue) {
            store.commit('setGlobalSearch', newValue);
        },
    });
</script>
