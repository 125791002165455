export const SELECT_MKB = 'SelectMKB';
export const SELECT_GENDER = 'SelectGender';
export const SELECT_CTZN = 'SelectCtzn';
export const SELECT_UNIT_PILL = 'SelectUnitPill';
export const SELECT_MEDICINES = 'SelectMedicines';
export const SELECT_EMPLOYEES = 'SelectEmployees';
export const SELECT_PRICES = 'SelectPrices';
export const SELECT_SEARCH_PILLS = 'SelectSEARCH_PILLS';

export const SELECT_UNIT_PRICE = 'SelectUnitPrice';
export const SELECT_PRICE_CATEGORIES = 'SelectPriceCategories';
export const SELECT_EMPLOYEES_JOB = 'SelectEmployeesJob';
export const BASE_PHONE_INPUT = 'BasePhoneInput';
export const AUTOCOMPLATE_PRICES = 'AutoComplatePrices';
export const AUTOCOMPLATE_SEARCH_PILLS = 'AutoSearchPills';

export const BASE_AUTOCOMPLATE = 'BaseAutoComplate';
export const BASE_CURRENCY_INPUT = 'BaseCurrencyInput';
