// Essantional items
import axios from 'axios';
import $cookies from 'vue-cookies';
// Toast
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import router from '@/router';

export const auth = axios.create({
    baseURL: `https://apixarid.e-konkurs.uz/api/v1` ?? `${process.env.VUE_APP_MAIN_URL_API1}`,
});

class Interceptors {
    requestResolve(config) {
        config.headers['Authorization'] = `Bearer ${$cookies.get('user_token') || null}`;
        return config;
    }

    requestReject(error) {
        return Promise.reject(error);
    }

    responseResolve(response) {
        return response.data;
    }

    async responseReject(error) {
        console.log('An error occurred:', error?.response?.data?.message);
        if (error?.response?.data?.message) {
            toast.error(`${error?.response?.data?.message}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        } else {
            toast.error(`${error}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        }

        if (error.response.status === 401) {
            $cookies.remove('user');
            $cookies.remove('user_token');
            router.push('/auth/login');
        } else {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
}

const instanceGenerator = (baseUrl) => {
    const interceptors = new Interceptors();
    const _axios = axios.create({
        baseURL: baseUrl,
    });
    _axios.interceptors.request.use(interceptors.requestResolve, interceptors.requestReject);
    _axios.interceptors.response.use(interceptors.responseResolve, interceptors.responseReject);
    return _axios;
};

export class InterceptorsWithWholeResponse {
    requestResolve(config) {
        config.headers['Authorization'] = `Bearer ${$cookies.get('user_token') || null}`;
        return config;
    }

    requestReject(error) {
        return Promise.reject(error);
    }

    responseResolve(response) {
        return response;
    }

    async responseReject(error) {
        console.log('An error occurred:', error?.response?.data?.message);
        if (error?.response?.data?.message) {
            toast.error(`${error?.response?.data?.message}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        } else {
            toast.error(`${error}`, {
                autoClose: 3000,
                toastStyle: {
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#000',
                },
            });
        }

        if (error.response.status === 401) {
            $cookies.remove('user');
            $cookies.remove('user_token');
            router.push('/auth/login');
        } else {
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
}

const instanceGeneratorResponseBlob = (baseUrl) => {
    const interceptors = new InterceptorsWithWholeResponse();
    const _axios = axios.create({
        baseURL: baseUrl,
        responseType: 'blob',
    });
    _axios.interceptors.request.use(interceptors.requestResolve, interceptors.requestReject);
    _axios.interceptors.response.use(interceptors.responseResolve, interceptors.responseReject);
    return _axios;
};

class Core {
    constructor(axios) {
        this._axios = axios;
    }

    get(url, config) {
        return this._axios.get(url, config).catch((error) => Promise.reject(error));
    }

    post(url, body = {}, config) {
        return this._axios
            .post(url, body, {
                ...config,
            })
            .catch((error) => Promise.reject(error));
    }

    put(url, body = {}) {
        return this._axios.put(url, body).catch((error) => Promise.reject(error));
    }

    delete(url, body = {}) {
        return this._axios.delete(url, body).catch((error) => Promise.reject(error));
    }
}

export const api = new Core(instanceGenerator('https://apixarid.e-konkurs.uz/api/v1' ?? process.env.VUE_APP_MAIN_URL_API1));
export const apiBlobResponse = new Core(instanceGeneratorResponseBlob('https://apixarid.e-konkurs.uz' ?? process.env.VUE_APP_MAIN_URL));

// Error handlers

const errorHandler = (error) => {
    console.log('An error occurred:', error?.response?.data?.message);
    toast.error(`${error?.response?.data?.message}`, {
        autoClose: 3000,
        toastStyle: {
            fontSize: '18px',
            fontWeight: '500',
            color: '#000',
        },
    });
};

auth.interceptors.response.use(
    (response) => response.data,
    (error) => {
        errorHandler(error);
        return Promise.reject(error);
    }
);
