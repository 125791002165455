<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>
            <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
                <template v-for="(item, index) in sidebarRoutes()">
                    <BaseSidebarItem v-if="item.can()" :label="$t(`admin.sidebar.${item.name}`)" :url="`/${ROOT}/${item.name}`" :icon="item.name" :key="index" />
                </template>

                <BaseSidebarItem
                    v-for="(item, index) in computedMenu"
                    :key="index"
                    :label="item.label"
                    :children="item.children"
                    :itemID="`menu_${item.id}`"
                    :url="item.url"
                    icon="factories"
                    :badgeCount="item.reports_count"
                />
            </perfect-scrollbar>
        </nav>
    </div>
</template>

<style lang="scss" scoped>
    .role-name {
        padding: 9px 10px;
        border-bottom: 1px solid #a9aec5;
    }
</style>

<script setup>
    import { computed, onMounted, ref } from 'vue';
    import { useStore } from 'vuex';
    import BaseSidebarItem from '../custom/BaseSidebarItem.vue';
    import { routes, ROOT } from '@/router/modules/admin';
    import { useList } from '@/composables/use-factories';
    import { isAdmin } from '@/utils/role';

    const sidebarRoutes = () => routes.filter((e) => !e.notSidebar());
    const store = useStore();

    const menu_collapse = ref('dashboard');

    const { getList } = useList();
    const computedMenu = computed(() => {
        return store.state.factories.map((e) => {
            return {
                url: null,
                icon: 'factories',
                badgeCount: e.reports_count,
                label: e.factoryName,
                id: e.id,
                children: [
                    {
                        label: 'Бизнес режа',
                        url: `/dashboard/factories/${e?.userId}/view`,
                        badge: e.reports_count,
                    },
                    {
                        label: 'Омборхона',
                        url: `/admin/dashboard/factories/${e?.userId}/warehouse`,
                    },
                ],
            };
        });
    });
    onMounted(() => {
        if (isAdmin()) {
            getList();
        }
        // const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
        // if (selector) {
        //     const ul = selector.closest('ul.collapse');
        //     if (ul) {
        //         let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
        //         if (ele) {
        //             ele = ele[0];
        //             setTimeout(() => {
        //                 ele.click();
        //             });
        //         }
        //     } else {
        //         selector.click();
        //     }
        // }
    });
</script>
