export const ACTION_VIEW = 'view';
export const ACTION_EDIT = 'edit';
export const ACTION_DELETE = 'delete';
export const ACTION_CANCEL = 'cancel';
export const ACTION_SAVE = 'save';
export const ACTION_PRINT = 'print';
export const ACTION_PDF = 'pdf';
export const ACTION_REJECT = 'reject';
export const ACTION_CONFIRM = 'confirm';
export const ACTION_ALLOW = 'allow';
export const ACTION_SEND = 'send';

export const ACTIONS = [ACTION_VIEW, ACTION_EDIT, ACTION_DELETE, ACTION_CANCEL, ACTION_SAVE, ACTION_PDF, ACTION_PRINT, ACTION_CONFIRM, ACTION_REJECT, ACTION_ALLOW, ACTION_SEND];
