import $cookies from 'vue-cookies';
import { ROLE_ADMIN, ROLE_FACTORY } from '@/enums/role';
import { isUndefinedOrEmptyStringOrNull } from './reuseble';
export function getUser() {
    return $cookies.get('user');
}
export function isAdmin() {
    return $cookies.get('user')?.role == ROLE_ADMIN;
}
export function isFactory() {
    console.log($cookies.get('user')?.role);
    return $cookies.get('user')?.role == ROLE_FACTORY;
}

export function getRoleText(role) {
    if (isUndefinedOrEmptyStringOrNull(role)) {
        return null;
    }
    if (isNaN(role)) {
        return role;
    }
    if (role == ROLE_ADMIN) {
        return 'admin';
    } else {
        return role;
    }
}
