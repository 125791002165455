import { usePaginatedData } from '@/composables/use-paginated-data';
import { ACTION_DELETE, ACTION_EDIT, ACTION_VIEW } from '@/enums/actions';
import { deleteDocumentById, getDocument, getDocumentById } from '@/services/factories';
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useModal } from './use-modal';
import { BASE_PHONE_INPUT, SELECT_CTZN, SELECT_GENDER } from '@/enums/component-names';
import { useStore } from 'vuex';
import { isAdmin } from '@/utils/role';

export const rewriteItem = (e) => {
    return {
        ...e,
        // name: e.user?.name,
        'factory[name]': e.factory?.name ?? e.name,
        factoryName: e.factory?.name ?? e.name,
        userId: e.user?.id ?? e?.id,
        id: e.factory?.id ?? e.id,
        phone: e?.user?.phone ?? e.phone,
    };
};

export const useSelectedFactory = () => {
    const store = useStore();
    const route = useRoute();
    const selectedFactory = computed(() => {
        if (!isAdmin()) {
            return null;
        }
        if (Array.isArray(store.state.factories)) {
            const result = store.state.factories.find((e) => e.userId == route.params.factoryId);
            return result;
        } else {
            return null;
        }
    });

    return {
        selectedFactory,
    };
};

export const useColumns = () => {
    return [
        {
            title: 'Завод номи',
            dataIndex: 'factory[name]',
            whiteSpaceNowrap: true,
            col: 8,
        },
        // {
        //     title: 'ФИО',
        //     dataIndex: 'name',
        //     whiteSpaceNowrap: true,
        //     col: 8,
        // },
        {
            title: 'Телефон рақам',
            dataIndex: 'phone',
            whiteSpaceNowrap: true,
            col: 5,
            input: BASE_PHONE_INPUT,
        },
        {
            title: 'Парол',
            dataIndex: 'password',
            whiteSpaceNowrap: true,
            col: 5,
            notTable: true,
        },
        {
            title: 'Паролни тасдиқлаш',
            dataIndex: 'password_confirmation',
            whiteSpaceNowrap: true,
            col: 5,
            notTable: true,
        },
    ];
};

export const useGetById = () => {
    const item = ref({});
    const loading = ref(false);

    const computedItem = computed(() => {
        return rewriteItem(item.value);
    });

    function getById(id) {
        loading.value = true;
        return new Promise((resolve) => {
            getDocumentById(id)
                .then((res) => {
                    item.value = res.data;
                    resolve(rewriteItem(res.data));
                })
                .finally(() => (loading.value = false));
        });
    }

    return {
        getById,
        computedItem,
        loading,
    };
};

export const useList = () => {
    const { total, list, pagination, loading } = usePaginatedData();
    const store = useStore();
    const computedList = computed(() => {
        return list.value.map((e) => {
            return rewriteItem(e);
        });
    });

    function getList(filter = {}) {
        list.value = [];
        total.value = 0;
        loading.value = true;

        return new Promise((resolve) => {
            getDocument({ ...pagination.value, ...filter })
                .then((res) => {
                    list.value = res.data;
                    total.value = res.total;

                    store.commit(
                        'setFactories',
                        res.data.map((e) => rewriteItem(e))
                    );
                    resolve(res);
                })
                .finally(() => (loading.value = false));
        });
    }

    return {
        getList,
        list,
        total,
        computedList,
        loading,
        pagination,
    };
};

export const useActions = () => {
    const currentItem = ref({});
    const router = useRouter();

    const { isOpen, close, open } = useModal();
    const getAction = ({ record, action }, { refresh } = { refresh: () => {} }) => {
        if (action == ACTION_DELETE) {
            if (confirm('Тасдиқлаш')) {
                deleteDocumentById(record.id).then(refresh);
            }
        }

        if (action == ACTION_EDIT) {
            router.push(`/dashboard/factories/${record.id}/edit`);
        }

        if (action == ACTION_VIEW) {
            open();
            currentItem.value = record;
        }
    };

    return {
        getAction,
        currentItem,
        isOpen,
        close,
    };
};
