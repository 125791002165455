<template>
    <button class="btn mr-2 btn-sm" style="padding: 5px 12px" :disabled="loading" @click="ok">
        <span stylw="width:0.5px;height:0.5px" v-show="loading" class="spinner-border spinner-border-sm text-white me-1 align-self-center loader-sm">Loading...</span>
        <slot />
    </button>
</template>

<script setup>
    import { defineEmits, defineExpose, ref } from 'vue';
    const emits = defineEmits(['ok']);
    const loading = ref(false);

    function ok() {
        emits('ok', (v) => {
            loading.value = v;
        });
    }

    defineExpose({
        ok,
    });
</script>

<style lang="scss" scoped></style>
