import { api } from '@/api';

export function createDocument(data) {
    return api.post(`/admin/factories`, data);
}

export function updateDocument(id, data) {
    return api.put(`/admin/factories/${id}`, data);
}

export function getDocument(params) {
    return api.get(`/admin/factories`, { params });
}

export function getDocumentById(id, params) {
    return api.get(`/admin/factories/${id}`, { params });
}
export function deleteDocumentById(id, params) {
    return api.delete(`/admin/factories/${id}`, { params });
}
